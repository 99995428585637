/* Cursor */

html, body{

margin: 0px;
padding: 0px;
font-family: 'Assistant', sans-serif;
background-color: white !important;
padding-top: 0px !important;
padding-left: 0px !important;
}

img{
  width: 100%;
}

header{
  width: 200px;
  height: 100vh;
  float: left;
  display: inline-block;
  left: 0px;
  position: absolute;
}

.page{
  width: 100% !important;
  padding-left: 200px;
  box-sizing: border-box;
}

ul{
  margin-left: 0px;
}

li{
  color: #A47B47;
}

a{
  text-decoration: none;
}

h1{
  font-size: 45px;
  font-weight: 600;
  color: #1B4610;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 0px;
}

h2{
font-size: 25px;
font-weight: 600;
color: #A47B47;
text-transform: uppercase;
}

strong{
font-size: 20px;
color: #1B4610;
font-weight: 600;
text-transform: upercase;
}


h3{
  font-size: 25px;
  color: #1B4610;
  margin-bottom: 13px;
  padding-bottom: 13px;
  position: relative;
}

h3::after{
  content: '';
  width: 100%;
  position: absolute;
  bottom: -13px;
  left: 0px;
}

p{
color: #A47B47;
font-size: 20px;
text-align: left;
line-height: 25px;

}

a{
  font-size: 15px;
  text-decoration: none;
  color: #A47B47;
}
