/* Wordpress CSS */

@import 'normalize';
@import "footer";
@import "header";
@import "grid";
@import "animations";
@import "spacing";
@import "single-modules";
@import "home";
@import "hypno";
@import "treatmens-child";
@import "responsiv";
