.shrink{
  width: 100%;
  left: 0px;
  height: 100vh;
  z-index: 9;
  position: relative;
}

.shrink img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.child .g-5{
  width: 100%;
}

.child .g-7{
  width: 58%;
  opacity: 0;
}

.appearchilds{
  position: absolute;
  top: 0;
  left: 0;
}

body{
  overflow: hidden;
}

.close {
    position: absolute;
    left: 3vw;
    top: 4vh;
}

.close img{
  width: 50px;
  height: 50px;
}

.backto img{
  width: 30px;
  margin-right: 10px;
}

.backto a{
   font-weight: lighter;
}



@media all and (-ms-high-contrast:none)
     {
     .treat { opacity: 1; } /* IE10 */
     .gebirgetxt{
           transform: translate(0px, 40px);
           width: 40%;
     }

     .treat img{
       max-height: 100% !important;
       padding: 40px;
       box-sizing: border-box;
     }

     .gebirge{
       transform: translate(0px, 0px);
       opacity: 1;
       width: 60%;
     }

     .child .module{
       width: 42% !important;
       opacity: 1 !important;
     }

     .child .g-7{
       width: 58% !important;
       opacity: 1 !important;
     }

     .shrink{
       opacity: 0;
     }

     .schrift{
       display: none;
     }


     *::-ms-backdrop, .treat { opacity:  1; } /* IE11 */



*::-ms-backdrop, .gebirge{
  transform: translate(0px, 0px);
  opacity: 1;
  width: 60% !important;
}


*::-ms-backdrop, .gebirgetxt{
      transform: translate(0px, 40px);
      width: 40%;
      opacity: 1 !important;
}



     }
