.mobile-menu .menu-item-453 img{
  padding-bottom: 10px;
}

@media only screen and (max-width: 1600px)  {

.treat-box{
  max-width: 90%;
}

}

@media only screen and (max-width: 1360px)  {

.treatments{
  overflow-y: scroll;
}

.treat-box{
  left: 0;
  top: 0;
  transform: translate(-0%, -0%);
  position: relative;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.treat{
  width: 31%;
  margin-bottom: 3%;
  padding: 20px;
  padding-bottom: 40px;
}

.treat .links{
  bottom: 20px;
}

.home .text-module p:last-of-type{

  margin-bottom: 100px;

}

.promise .g-6{
  width: auto;
}

.text-module p{
  opacity: 1;
}

}

@media only screen and (max-width: 1200px)  {

.treat{
  width: 31%;
  margin-bottom: 3%;
}

.home-animation p, .home-animation p strong{
  font-size: 50px;
  line-height: 45px;
}

}

@media only screen and (max-width: 1024px)  {



  .treatments{
    height: auto;
  }

  .page{
    left: 0px;
    position: relative;
}

header{
  position: fixed;
  left: 0px;
  top: 0px;
}

body{
  overflow: visible;
  height: auto;
}

.text-scroll{
overflow: visible;
}



.g-5{
  width: 100% !important;
  height: auto;
}

.g-7{
  width: 100% !important;
  height: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.g-5,
.g-5 img{
  height: 50vh;
}

.schrift span{
  font-size: 180px;
}

.appearchilds{
  left: 50%;
  transform: translateX(-50%);
}

.schrift span{
  animation-duration: 20s;
}

@keyframes slide{
  0%{
    left: 50%;
  }

  50%{
    left: 10%;
  }

  100%{
    left: 50%;

  }

}

.text-scroll {
    width: 100%;
    padding-left: 3vw;
    padding-right: 3vw;
    margin: 0 auto;
    margin-top: 5vh;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 40px;
    height: auto;
    overflow-y: auto;
    margin-bottom: 5vh;
}

.child .text-scroll{
  margin-top: 10vh;
}

.close{
  left: 5vw;
}


  .home-animation{
    width: 100%;
  }

  .home-animation p,
  .home-animation p strong{
    font-size: 35px;
    line-height: normal;
  }

  .treat p{
    font-size: 18px;
    line-height: normal;
    }

  .home-animation-bottom{
    bottom: 0px;
  }

  .home-animation-bottom p,{
    font-size: 20px;
  }

  .treatmens{
    overflow: auto;
  }

  .home .text-module{
    max-width: 80vw;
  }

}

@media only screen and (max-width: 768px)  {



  .mobile-menu{
    display: block;
  }

  .desktop{
    display: none;
  }

  .mobile{
    display: block;
  }

  header{
    position: relative;
    display: inline-block;
    height: 90px;
    width: 100%;
    position: fixed;
    z-index: 11;
    overflow: visible !important;
  }

  .text-scroll{
    overflow: visible !important;
  }

.page{
  width: 100%;
  left: 0px;
  padding-left: 0px;
  padding-top: 90px;
}

.text-scroll p{
  word-break: break-word;
}

}


@media only screen and (max-width: 480px)  {

  .open ul{
    margin-top: 0px;
    margin-bottom: 150px;
  }

  .headline img{
    width: 100%;
  }

  .open li{
    width: 100%;
  }

  .treat{
    width: 100%;
    margin-bottom: 5%;
  }

.home-animation p, .home-animation p strong{
  font-size: 28px;
}

.g-5, .g-5 img{
  height: 450px;
}

.home .text-module p:last-of-type{
  margin-bottom: 50px;
}

}

@media screen and (max-width: 1024px),
       screen and (max-height: 900px) {

.treatments{
  overflow-y: scroll;
}


.treat-box{
  margin-top: 4vw;
  margin-bottom: 4vw;

  top: 0px;
transform: translate(-0%, 0px);
left: 0%;
margin: 40px;
margin-left: auto;
margin-right: auto;
}

.g-3.treat{
  width: 48%;
}

.page{
  left: 0px;
  position: relative;
}

header{
position: fixed;
left: 0px;
top: 0px;
overflow-y: auto;
}

header ul{
  padding-top: 40px;
padding-left: 20px;
padding-bottom: 40px;

}

body{
}

.text-scroll{
overflow-y: scroll;
}

}
