/* Header */

body {
    margin: 0;
    height: 100vh;
    background: rgb(27, 27, 27);
}

header{
  background-color: white;
}

header img{
  width: 100%;
}

header ul{
  margin:0;
  padding: 0;
  position: relative;
  display: inline-block;
  float: left;
  padding-top: 80px;
  padding-left: 20px;
}

header ul li{
  list-style-type: none;
  text-decoration: none;
}

header ul a{
  color: #A47B47;
  font-size: 20px;
  text-transform: uppercase;
  opacity: 0.5;
  padding-bottom: 13px;
  margin-bottom: 13px;
  display: inline-block;
  text-decoration: none;
  position: relative;
  font-weight: 600;
  transition: 0.4s;
}

.mobile-menu .logo{
  opacity: 0;
}

.open .logo{
  opacity: 1;
}

header ul:hover a::after{
  opacity: 1;
}

header ul a::after{
position: absolute;
width: 25px;
content: '';
height: 2px;
background-color: #A47B47;
opacity: 0.5;
bottom: 0px;
left: 0;
transition: 0.4s;
}

header ul .current-menu-item a,
header ul .current-menu-item a:hover,
header ul .current-page-ancestor a,
header ul .current-page-ancestor a:hover,
.current_page_item a{
  opacity: 1;
  transition: 0.4s;
  transition-timing-function: ease-in-out;
}

header ul .current-menu-item a::after,
header ul .current-page-ancestor a::after,
header ul .current-page-ancestor li:hover a::after,
.current_page_item a{
  opacity: 1;
  width: auto;
  min-width: 50px;

}

.text-module h2,
{
  color: #1B4610;
  position: relative;
  font-weight: 400;
  padding-bottom: 0px;
  margin-bottom: 30px;
}

.text-module h2::after{
  width: 126px;
  position: absolute;
  left: 2px;
  bottom:-13px;
  height: 1px;
  background-color: rgba(164, 123, 71, 0.5);
  content: ",";
  font-size: 0px;
}

 header .mobile span{
  width: 42px;
    height: 3px;
    margin-bottom: 10px;
    background-color: #A47B47;
    position: relative;
    display: inline-block;
    float: left;
}

 header .mobile span:last-of-type{
  margin-bottom: 0px;
}

.wpml-ls-slot-10{
  display: inline-block;
  opacity: 0.7;
  width: 30px;
}

.wpml-ls-slot-10.wpml-ls-current-language{
  opacity: 1;
  float: left;
}

.wpml-ls-slot-10 a::after{
height: 0px !important;;
}

.wpml-ls-slot-10.wpml-ls-current-language a::after{
  height: 2px !important;;
}

.socials{
  position: absolute;
  bottom: 20px;
  left: 30px;
  width: auto;
}

.socials img{
  width: 22px;
  height: 22px;
  margin-right: 10px;
}



header ul .wpml-ls-item::after{
  height: 0px;
}

header ul .wpml-ls-item.wpml-ls-current-language::after{
  height: 2px;
}

header ul .wpml-ls-item.wpml-ls-current-language{
  float: left;
}

.menu-m{
  position: absolute;
    top: 30px;
    right: 40px;
    width: 42px;
    height: 30px;
}

.mobile{
  display: none;
}

.mobile-menu{
  position: fixed;
top: 90px;
right: 0px;
background-color: rgba(27, 70, 16, 1);
width: 100%;
height: 0%;
visibility: hidden;
display: none;
padding-top: 5vh;
}

.mobile-menu.open{
  overflow: auto;
  z-index: 10;

}

.mobile-menu .current-menu-item a,
.mobile-menu .wpml-ls-current-language a{
  border-bottom: 2px solid #A47B47;
  color: #A47B47;
  margin-bottom: 8px;
}

.mobile-menu .current-menu-item{
  margin-bottom: 10px;
}

.open .wpml-ls-slot-10.wpml-ls-current-language{
  padding-right: 0px;
  opacity: 0;
  width: 80px;
}

.layer{
  transition: 0.4s;
  transition-timing-function: ease-out;
}

.mobile-menu.open{
  visibility: visible;
  height: 100%;
}

.g-5n,
.g-5n img{
  transition: 0.6s;
  transition-timing-function: ease-out;
}

.g-5n.men,
.g-5n.men img{
  height: 100vh;
  z-index: 9;
  opacity: 1;
}

.g-5n.men{
  opacity: 0.4 !important;
}

.mobile-menu li{
  font-size: 20px;
  text-transform: uppercase;

}

.menu-m span,
.mobile-menu a{
  transition: 0.3s;
  transition-timing-function: ease-out;
}

.rotate span:nth-of-type(1){
transform: rotate(45deg);
top: 12px;
}

.rotate span:nth-of-type(2){
opacity: 0;
}

.rotate span:nth-of-type(3){
  transform: rotate(-45deg);
  top: -13px;
}

.mobile-menu .wpml-ls-slot-10.wpml-ls-current-language li,
.mobile-menu li{
  opacity: 0;
}

.open li{
  animation: fade-top forwards;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  position: relative;
  text-decoration: none;
  list-style-type: none;
  width: 50%;
  float: left;
  margin-bottom: 10px;
  height: 50px;
}

.open ul li a::after{
  display: none;
}

.open li a{
  font-size: 30px;
  color: white;
  transition: 0.4s;
  transition-timing-function: ease-out;
  opacity: 1;
  padding-bottom: 0px;
}

.open ul{
  padding-left: 0px;
  margin-left: 40px;
  margin-top: 100px;
  margin-right: 40px;
  margin-bottom: 100px;
  display: inline-block;
  position: relative;
}

.mobile-menu .logo{
  width: 200px;
  margin: 0 auto;
}

.open li:nth-of-type(2){
  animation-delay: 0s;
}
.open li:nth-of-type(3){
  animation-delay: 0.2s;
}
.open li:nth-of-type(4){
  animation-delay: 0.2s;
}
.open li:nth-of-type(5){
  animation-delay: 0.4s;
}
.open li:nth-of-type(6){
  animation-delay: 0.4s;
}
.open li:nth-of-type(7){
  animation-delay: 0.6s;
}
.open li:nth-of-type(8){
  animation-delay: 0.8s;
  width: auto;
  margin-right: 20px;
}
.open li:nth-of-type(9){
  animation-delay: 0.8s;
}

.open li:hover a{
  color: #A47B47;
}


@keyframes fade-top{

  0%{
    top: 30px;
    opacity: 0;
  }

  50%{
    opacity: 0.8;
  }

  100%{
    top: 0px;
    opacity: 1;
  }

}

.menu-item-452 a{
  opacity: 1;
}

.menu-item-452 a::after{
  width: 0px !important;
}

.menu-item-452 img{
  height: 40px;
}

.menu-item-453 a{
  opacity: 1;
}

.menu-item-453 img{
  height: 30px;
}

.menu-item-453 a::after{
  width: 0px !important;
}
