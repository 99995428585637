/* single-modules */

.text-scroll{
  width: 100%;
  padding-left: 3vw;
  padding-right: 3vw;
  margin: 0 auto;
  margin-top: 10vh;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 40px;
  height: 80vh;
  overflow-y: auto;
  margin-bottom: 10vh;
}

.text-scroll p{
  opacity: 0.6;
  margin-bottom: 25px;
  transition: 0.2s;
}

.text-module ul{
  padding-left: 20px;
  display: inline-block;
  margin-bottom: 30px;
}

.text-module ul li{
  font-size: 20px;
  opacity: 0.6;
  transition: 0.3s;
}

.text-module ul li:hover{
  opacity: 1;
}

.text-scroll p:hover{
  opacity: 1;
}

.headline h2{
  margin: 0px;
  padding: 0px;
}

.headline img{
  margin-top: 30px;
  width: 100%;
  float: left;
  max-width: 695px;
  margin-bottom: 50px;
  width: 80%;
}

.text-module{
max-width: 666px;
float: left;
position: relative;
}

.text-module a{
  font-size: 20px;
  font-weight: lighter;
}

.treatments{
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.treat-box{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  width: 100%;
  max-width: 70%;
}

.treat{
  background-color: #F5F5F5;
  border: 2px solid #f5f5f5;
  padding: 38px;
  padding-bottom: 70px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 2%;
  box-sizing: border-box;
  width: 23%;
  transition: 0.3s;
  transition-timing-function: ease-out;
  opacity: 0;
}

.treat p{
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #1B4610;
  margin-bottom: 0px;
  transition: 0.5s;
  transition-timing-function: ease-out;

}

.links{
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 25px;
}

.treatments h3{
  height: 65px;
  font-weight: 400;
}

.treatments img{
  max-height: 180px;
  object-fit: contain;
}

.treatments h3::after{
  height: 1px;
  background-color: #A47B47;
  bottom: 0px;
}

.promise{
  width: 100%;
  justify-content: left;
}

.promise .g-6{
  padding-right: 30px;
  width: auto;
}

.treat:hover{
  background: #1B4610;
  border: 2px solid #a47b47;
}

.treat:hover p{
  color: #A47B47;
}

.backto{
  width: 100%;
  float: left;
  margin-bottom: 100px;
}

.backto a{
  text-transform: uppercase;
}

.arrow{
  width: 30px;
  float: left;
}

.data{
  margin-top: 5vw;
  overflow: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 90%;
  float: left;
}
