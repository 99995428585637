.page{
  width: calc(100% - 200px);
  float: left;
}
.flex {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  }

  .col{
    position: relative;
    float: left;
  }

.g-1 {
width: 7.6%; }

.g-2 {
width: 16%; }

.g-3 {
width: 24.4%; }

.g-4 {
width: 32.8%; }

.g-4:nth-child(3n+1) {
margin-left: 0; }

.qlink {
width: 32.8333%; }

.g-5 {
width: 42%;
height: 100vh;
}

.g-5 img{
height: 100%;
object-fit: cover;
}

.g-50 {
width: 50%; }

.g-6 {
width: 45%; }

.g-7 {
  box-sizing: border-box;
      width: 58%;
      height: 100vh;
      padding-right: 40px;}

.g-8 {
width: 67.2%; }

.g-9 {
width: 74.8%; }

.g-10 {
width: 83.2%; }

.g-11 {
width: 91.6%; }

.g-12 {
width: 100%; }
