/* HOME */

.gebirgetxt.g-7{
  width: 40%;
}

.gebirge.g-5{
  width: 100%;
  padding-right: 0px;
}

.gebirgetxt .text-module{
  max-width: 450px;
}

@media (min-width: 1500px) {

.gebirgetxt .text-module{
  max-width: none;
}

 }


.home .g-6{
  width: auto;
  box-sizing: border-box;
}

.home .text-module p:last-of-type{
  margin-bottom: 50px;
}

.home .headline img{
  max-width: 440px;
}

.contact-field .g-6{
  width: 45%;
}

.promise .g-6{
  width: auto;
  float: left;
}

.gebirgetxt{
  opacity: 0;
}

.contact-field a{
  text-transform: none;
  word-break: break-word;
}

.contact-field img{
  width: auto;
}

.contact-field{
  margin-bottom: 100px;
}

h2.mails::after{
  width: 40px;
}
